.wrapper {
    position: relative;
    width: 100%;
    height: 38px;
}

.dayPicker {
    position: absolute;
    z-index: 9;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}