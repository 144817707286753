$red: #aa0000;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.nav-button.active {
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
    color: white;
}

// button {
//     background-color: $red !important;
// }