// tailwind colors
$red400: #f87171;
$red500: #ef4444;
$blue400: #60a5fa;
$green400: #34d399;
input[type="button"]:disabled,
button:disabled {
    cursor: not-allowed !important;
}

.table-with-overflow > tbody {
    display: block;
    overflow: auto;
    max-height: 30rem;
    width: 100%;
  }
  
.table-with-overflow > tbody > *, .table-with-overflow > thead tr {
    display: inline-table;
    width: 100%;
}

// react toastify overrides
.Toastify__toast--error {
    background: $red500;
}
.Toastify__toast--info {
    background: $blue400;
}
.Toastify__toast--success {
    background: $green400;
}
.Toastify__toast-body {
    font-family: system-ui, -apple-system, /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        width: 100%;
}
